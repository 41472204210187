import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { useShipmentApi } from '@api/ShipmentApi';
import { Card } from '@atoms/Card';
import { IconSVG } from '@atoms/IconSVG';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useUserContext } from '@contexts/user';
import {
  ShipmentStatus,
  StatusHistory,
  shipmentStatusList,
} from '@definitions/Shipment';
import { useDisablePdfDownload } from '@hooks/useDisablePdfDownload';
import { useDownloadShipmentPdf } from '@hooks/useDownload';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import { useShipmentData } from '@hooks/useShipmentData';
import { ActivityCardProps } from '@molecules/ActivityCard/definitions';
import { Select } from '@molecules/Form/Select';
import { useMutation } from '@tanstack/react-query';

const formatStatusLabel = (status: string) =>
  status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

const ActivityCardDesktop = ({
  shipment,
  canChangeStatus,
  renderFooter,
}: ActivityCardProps) => {
  const { t } = useTranslation();
  const { user, isDriver } = useUserContext();
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const isPdfDownloadDisabledStatus = useDisablePdfDownload(shipment?.status);

  const {
    id,
    code,
    status,
    ownerOrganization,
    isUrgent,
    createdAt,
    statusHistory,
    desiredDeliveryDate,
  } = shipment;

  const acceptedWithReserveCount = useMemo(
    () =>
      shipment?.statusHistory.filter(
        (el: StatusHistory) => el.withReserve === true,
      ).length,
    [shipment?.statusHistory],
  );
  const { getShipmentType, getShipmentTotalProducts, getShipmentProductStyle } =
    useShipmentData();

  const { updateShipmentStatus } = useShipmentApi();
  const { mutate: callUpdateShipmentStatus } = useMutation({
    mutationFn: updateShipmentStatus,
  });
  const shipmentType = getShipmentType(shipment, user);
  const shipmentTotalItems = getShipmentTotalProducts(shipment);
  const entryDate = createdAt
    ? dayjs(createdAt).format('DD/MM/YYYY')
    : undefined;
  const deliveryReq = desiredDeliveryDate
    ? dayjs(desiredDeliveryDate).format('DD/MM/YYYY')
    : undefined;

  const {
    showConfirmationModal,
    showShipmentDetailsModal,
    showShipmentAttachCommentModal,
  } = useModalsContext();

  const icon = useMemo(
    () => (shipmentType === 'incoming' ? 'download-box' : 'upload-box'),
    [shipmentType],
  );
  const shouldDataSpan = !isUrgent && !shipmentTotalItems;

  const { styleId, styleIdType } = getShipmentProductStyle(shipment);

  const [selectValue, setSelectValue] = useState<string>(
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
  );

  const onStatusChangeHandler = useCallback(
    (newValue: string) => {
      showConfirmationModal({
        title: t('InCourse.ConfirmationModal.title'),
        content: t('InCourse.ConfirmationModal.content'),
        onConfirm: () => {
          callUpdateShipmentStatus(
            {
              shipmentID: id,
              status: newValue.toLowerCase() as ShipmentStatus,
            },
            {
              onSuccess: () => {
                invalidateAllShipmentQueries();
              },
            },
          );
          setSelectValue(newValue);
        },
      });
    },
    [
      callUpdateShipmentStatus,
      id,
      invalidateAllShipmentQueries,
      showConfirmationModal,
      t,
    ],
  );

  // Comments
  const isCommentDisabled = useMemo(() => {
    if (isDriver) {
      return true;
    }

    return (
      status !== 'arrived' ||
      user?.organization.id !== shipment.receiverOrganizationId
    );
  }, [
    isDriver,
    shipment.receiverOrganizationId,
    status,
    user?.organization.id,
  ]);

  // Pdf
  const isPdfDownloadDisabled = useMemo(
    () => isPdfDownloadDisabledStatus || isDriver,
    [isDriver, shipment?.status],
  );

  const { downloadShipmentPdf, isLoading: isDownloadingShipmentPdf } =
    useDownloadShipmentPdf();

  return (
    <Card backgroundColor="bg-Primary-05" className="py-2 px-3">
      <div className="flex w-full items-center">
        <div className="flex w-full items-center">
          <IconSVG icon={icon} size={24} className="my-2 mx-3" />
          <div className="px-2 border-l border-Primary-02 flex items-start flex-col ">
            <Typography isBold size="sm" sizeMd="xl" isUppercase>
              {t('Home.ActivityCard.ID')}: {code}
            </Typography>
            {acceptedWithReserveCount > 0 && (
              <div className="flex items-center">
                <IconSVG icon="shield-warning" size={16} />
                <Typography size="xs" color="text-Primary-03" className="ml-1">
                  {t('Home.ActivityCard.acceptedWithReserve')}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {canChangeStatus ? (
          <Select
            value={selectValue}
            onChange={(newValue) => onStatusChangeHandler(newValue)}
            options={shipmentStatusList.map((status) => ({
              value: formatStatusLabel(status),
              label: formatStatusLabel(status),
            }))}
            bold
          />
        ) : (
          <Typography isBold size="sm" className="md:mr-8 capitalize">
            {status}
          </Typography>
        )}
      </div>
      <div className="w-full grid grid-cols-2 gap-23 items-center">
        <div className="w-full col-span-1">
          <div className="mt-3">
            <div className="w-full flex gap-2 flex-col">
              <div className="grid grid-cols-4 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.address')}
                </Typography>
                <div className="col-span-3">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {ownerOrganization.address}
                  </Typography>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.owner')}
                </Typography>
                <div className="col-span-3">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {ownerOrganization.name}
                  </Typography>
                </div>
              </div>
              {styleIdType && (
                <div className="grid grid-cols-4 gap-4">
                  <Typography color="text-Primary-02">
                    {t(`General.${styleIdType}`)}
                  </Typography>
                  <div className={`${shouldDataSpan ? 'col-span-3' : ''}`}>
                    <Typography className="truncate overflow-hidden text-ellipsis ">
                      {styleId}
                    </Typography>
                  </div>
                  {shipmentTotalItems && (
                    <div className="text-center col-start-4 text-sm text-Primary-03">
                      {shipmentTotalItems}
                    </div>
                  )}
                </div>
              )}
              <div className="grid grid-cols-4 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.entryDate')}
                </Typography>
                <div className={`${shouldDataSpan ? 'col-span-3' : ''}`}>
                  <Typography className="overflow-hidden text-ellipsis ">
                    {entryDate}
                  </Typography>
                </div>
                {isUrgent && (
                  <div className="text-center col-start-3">
                    <div className="p-[2px] w-fit  flex justify-center  bg-Urgent-red">
                      <Typography isUppercase size="sm" color="text-Primary-05">
                        {t('Home.ActivityCard.urgent')}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              {deliveryReq && (
                <div className="grid grid-cols-4 gap-4">
                  <Typography color="text-Primary-02">
                    {t('Home.ActivityCard.deliveryReq')}
                  </Typography>
                  <div className="col-span-3">
                    <Typography className="overflow-hidden text-ellipsis ">
                      {deliveryReq}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-full col-span-1 flex flex-col justify-center ">
          {statusHistory
            ?.slice(-3)
            .map((statusHistoryItem, index, statusHistoryArray) => {
              const isLastStatus =
                index === statusHistoryArray.slice(-3).length - 1;
              return (
                <div
                  key={index}
                  className={`flex w-full items-center bg-custom ${isLastStatus ? "bg-[url('/public/delivery.svg')]" : "bg-[url('/public/delivery-grey.svg')]"} bg-no-repeat pl-10 bg-full`}
                >
                  <div className="mt-1 w-full flex flex-col">
                    <Typography
                      color={
                        isLastStatus ? 'text-Primary-00' : 'text-Primary-03'
                      }
                      isBold
                      size="sm"
                    >
                      {t(`General.ShipmentStatus.${statusHistoryItem.status}`)}
                    </Typography>
                    <div className="grid w-full grid-cols-2 justify-items-start items-start">
                      <Typography size="xs">
                        {`${statusHistoryItem.user.firstName} ${statusHistoryItem.user.lastName}, ${statusHistoryItem.userOrganization?.name}`}
                      </Typography>
                      {statusHistoryItem.withReserve && (
                        <IconSVG icon="shield-warning" size={16} />
                      )}
                    </div>
                    <Typography size="xs">
                      {dayjs(statusHistoryItem.setAt).format(
                        'DD/MM/YYYY at HH:mm',
                      )}
                    </Typography>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="mt-12 flex w-full items-center">
        <div className="w-full">
          <button
            onClick={() => {
              showShipmentDetailsModal({
                shipment,
              });
            }}
            className="flex items-center w-full"
          >
            <Typography className="mr-1">
              {t('Home.ActivityCard.viewDetails')}
            </Typography>
            <IconSVG icon="chevron-right" size={12} />
          </button>
        </div>
        <div className="mr-8 w-full flex justify-end gap-7">
          <IconSVG
            icon={shipment.delayedComment ? 'tooltip-notification' : 'tooltip'}
            size={16}
            onClick={() =>
              showShipmentAttachCommentModal({
                shipmentId: shipment.id,
                delayedComment: shipment.delayedComment,
              })
            }
            disabled={isCommentDisabled}
          />
          {isDownloadingShipmentPdf ? (
            <Spinner size="sm" className="inline-flex" />
          ) : (
            <IconSVG
              icon="download"
              size={18}
              onClick={() => downloadShipmentPdf(shipment.id)}
              disabled={isPdfDownloadDisabled}
            />
          )}
        </div>
      </div>
      {renderFooter?.(shipment)}
    </Card>
  );
};

export default React.memo(ActivityCardDesktop);
