import { Organization } from '@definitions/Organization';
import { Packaging } from '@definitions/Packaging';
import { Product } from '@definitions/Products';
import { Support } from '@definitions/Support';
import { User } from '@definitions/Users';

export type Shipment = {
  id: number;
  ownerOrganizationId: number;
  senderOrganizationId: number;
  receiverOrganizationId: number;
  code: string | null;
  status: ShipmentStatus;
  isUrgent: boolean;
  desiredDeliveryDate: string | null;
  urgentReason: string | null;
  creationFlow: string | null;
  delayedComment: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  ownerOrganization: Organization;
  receiverOrganization: Organization;
  senderOrganization: Organization;
  shipmentSupports: Array<ShipmentSupport>;
  shipmentPackagings: Array<ShipmentPackaging>;
  shipmentProducts: Array<ShipmentProduct>;
  statusHistory: Array<StatusHistory>;
};

export type ShipmentSupport = {
  id: number;
  shipmentId: number;
  supportId: number;
  quantity: number;
  support: Support;
};

export type ShipmentPackaging = {
  id: number;
  shipmentId: number;
  packagingId: number;
  quantity: number;
  packaging: Packaging;
};

export type ShipmentProduct = {
  id: number;
  shipmentId: number;
  productId: number;
  reasonForRequestId: number;
  billableCenterCostId: number | null;
  billable: boolean;
  comment: string | null;
  isAccepted: boolean;
  product: Product;
};

export type StatusHistory = {
  id: number;
  shipmentId: number;
  status: ShipmentStatus;
  withReserve: boolean;
  comment: string | null;
  imageKey: string | null;
  setBy: number;
  setAt: string;
  user: User;
  userOrganization: {
    id: number;
    organizationTypeId: number;
    name: string;
    address: string;
    enabled: boolean;
  };
};

export const shipmentStatusList = [
  'pending',
  'checking',
  'validated',
  'moving',
  'arrived',
  'delivered',
  'refused',
  'cancelled',
] as const;

export type ShipmentStatus = (typeof shipmentStatusList)[number];

export enum ShipmentType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export type ShipmentRemarks = {
  image?: File | undefined;
  urgentDelivery?: boolean;
  desideredDeliveryDate?: Date | undefined;
  comment: string | undefined;
  recipientName?: string | undefined;
};
